.footer{
    display: flex;
    flex-direction: column;
    align-items: center;
    /* display: flex;
    flex-direction: column;
    align-items: center; */
    color: rgba(0,0,0);
    background-color: #132733;
    font-family: Montserrat,Tiny5;
}
.footerMain{
    display: flex;
    /* display: flex;
    justify-content: space-evenly;
    text-align: center; */
    padding: 1rem;
}
/* .fIcons:hover{
    padding: 0.5rem;
    border-bottom: #25D366;
}
.fIcons:active{
    border-bottom: 1px solid;
} */
.left,.mid,.right{
    display: flex;
    flex-direction: column;
    padding-top: 2rem;
    color: #fff;
}
.left h1,.mid h1,.right h1{
    padding: 2rem 0;
    font-size: x-large;
}
.mid ul,.right ul,.left .futs{
    padding: 1.5rem;
    /* font-size: medium; */
}
.mid ul li,.right ul li{
    list-style: none;
}
.mid{
    align-items: center;
}
.right,.left h1{
    align-items: center;
}
.futs{
    display: flex;
    flex-direction: column;
    /* display: flex;
    flex-direction: column;
    justify-content: space-between; */
}
.futsee{
    display: flex;
    justify-content: space-around;
    /* display: flex; */
    justify-content: space-between;
}
.futsee h3{
    /*  */
}
.futsee p{
    margin-left: 1rem;
}
.futsee h3:hover{
    color: #25D366;
}
.futsee p:hover{
    border-bottom: 1px solid #25D366;
}
.futsii{
    display: flex;
    justify-content:space-between;
    align-items: center;
}
.futsee,.futsii li a{
    padding: 0.2rem 0;
}
.futsii li a{
    /* display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center; */
    padding: 0rem 0.3rem;
    margin: 0rem 0.5rem;
}
.footnote{
    padding: 1rem 0;
    /* text-align: center; */
    font-weight: 500;
}
.copyright{
    font-weight: bold;
}

/* Compatibility to other devices */
@media screen and (max-width: 768px){
    /* .footer{
        display: flex;
    } */
    .footerMain{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .futsii{
        display: flex;
        /* align-items: center;
        justify-content: center; */
    }
    .left,.mid,.right{
        font-size: medium;
    }
    .right{
        display: flex;
        align-items: center;
    }
    .left h1{
        /* justify-content: center;
        align-items: center; */
        text-align: center;
    }
    .footnote{
        padding-bottom: 2.5rem;
    }
}
@media screen and (max-width:425px) {
    .left,.mid,.right{
        font-size: medium;
    }
}
@media screen and (max-width:375px) {
    .left,.mid,.right{
        font-size: small;
    }
}
@media screen and (max-width: 320px) {
    .left,.mid,.right{
        font-size: smaller;
    }
}
