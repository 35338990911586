.app__afiliate-contain{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.app__afiliate-contain h1{
    display: flex;
    padding: 1rem;
    margin: 0.5rem;
    justify-content: center;
    align-items: center;
}
.app__afiliate-products{
    margin-top: 2.5rem;
    display: flex;
    justify-content: space-around;
    align-items: center;
    cursor: pointer;
    width: 90%;
    height: 60vh;
}
.app__afiliate-product{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin: 0 1rem;
    padding: 1rem 0;
    width: 20rem;
    height: 28rem;
}
.app__afiliate-product img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.app__afiliate-product-text{
    position: absolute;
    margin-top: 14rem;
    padding: 0.5rem;
}
.app__afiliate-top{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.app__afiliate-bottom{
    display: flex;
    justify-content: center;
    align-items: center;
}
.swiper-container{
    width: auto;
}
    
@media screen and (max-width: 320px) {
    .swiper-container{
        width: 320px;
    }
}


@media screen and (min-width: 320px) and (max-width:768px){
    .app__afiliate-contain{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: auto;
        margin-left: -1rem;
    }
    .app__afiliate-contain h1{
        /* margin-top: -2rem; */
        margin-bottom: 1rem;
        padding: 1rem 0;
    }
    .app__afiliate-products{
        margin: 0;
        padding: 1rem 0;
    }
    .app__afiliate-product{
        width: auto;
        height: 24rem;
    }
}
@media screen and (max-width: 425px){
    .swiper-container{
        width: 425px;
    }
    .app__afiliate-contain{
        margin-left: 0rem;
    }
}
@media screen and (min-width: 768px) {
    .swiper-container{
        width: 768px;
    }
    .app__afiliate-contain{
        margin-left: 0;
    }
    .app__afiliate-products{
        margin: 0;
        padding: 1rem 0;
    }
    .app__afiliate-product{
        width: auto;
        height: 24rem;
    }
}