.app__product{
    padding: 0.5rem;
    position: relative;
    display: flex;
    flex-direction: column;
    color: #fff;
    background-color: rgba(0,0,0,0.2);
    border-radius: 0.5rem;
}
.app__product:hover{
    color: orange;
}

/* @media screen and (min-width: 320px) {
    .app__product{
        width: 100%;
        background-color: rgba(225,165,0,0.1);
        color: 100%;
    }
} */