nav{
    width: 100%;
    display: flex;
    justify-content: space-between;
    background-color: #132733;
    /* --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity)); */
    border-bottom: 1px solid #132733;
    position: fixed;
    font-family: Montserrat,Tiny5;
    z-index: 2;
}
.navLogo{
    height: 4rem;
    width: 4rem;
    display: flex;
    align-items: center;
    margin: 0.5rem;
}
.logo-txt{
    color: #fff;
    font-size: large;
    padding-left: 1rem;
}
/* .logo-txt:hover{
    color: #132733;
    font-weight: 600;
    font-size: larger;
} */
.menu{
    display: flex;
    align-items: center;
}
.menu-item{
    display: flex;
    padding: 1rem 5rem;
    margin: 0rem 1rem;
    /* color: #132733; */
    color: #fff;
    font-size:larger;
    font-weight: 600;
}
.menu-item:hover,.burger:hover{
    /* background-color: orange; */
    border-radius: 1rem;
    color: #132733;
    background-color: #25D366;
    /* font-family: Dancing Script; */
}
/* .menu-item:active{
    border-bottom: 1px solid orange;
} */
.menu-item:visited{
    border-bottom: 1px solid #132733;
}
/* .burger:hover{
    background-color: #25D366;
    border-radius: 1rem;
    color: #132733;
} */
.sm-menu{
    display: none;
}

.hamburger{
    display: flex;
    justify-content: center;
    width: 100%;
}
.burgers{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* border: 1px solid orange; */
    background-color: transparent;
}
.burger{
    color: #fff;
    padding: 1rem;
    font-size: larger;
    margin: 1rem;
    font-weight: 600;
}

/* Small devices */
@media screen and (max-width:768px){
    .sm-menu{
        text-align: center;
    }
    .hamburger{
        /* text-align: center; */
    }
    .burger{
        font-size:large;
        padding-left: 2rem;
        padding-right: 2rem;
    }
}
@media screen and (max-width:425px){
    .burger{
        font-size:medium;
    }
}
@media screen and (max-width:320px){
    .burger{
        font-size: small;
    }
}


@media screen and (min-width:425px) and (max-width:768px){
    .navLogo{
        height: 3.2rem;
        width: 3.2rem;
        padding: 0rem;
    }
    .logo-txt{
        /* display: none; */
        color: #fff;
        font-size: medium;
        padding: 0.5rem;
    }
}
@media screen and (max-width:375px){
    nav{
        
    }
    .navLogo{
        /* text-align: center; */
        height: 3rem;
        width: 3rem;
        padding: 0rem;
    }
    .logo-txt{
        /* display: none; */
        color: #fff;
        font-size: medium;
        padding-left: 0.5rem;
    }
}
@media screen and (max-width:320px){
    nav{
        font-size:medium;
    }
    .navLogo{
        /* text-align: center; */
        height: 3rem;
        width: 3rem;
        padding: 0.1rem;
    }
    .logo-txt{
        color: #fff;
        font-size: smaller;
        padding-left: 0.5rem;
    }
    
}
@media screen and (min-width: 320px) and (max-width: 768px){
    .menu{
        display: none;
    }
    .sm-menu{
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: larger;
        margin: 0rem 1rem;
        text-align: center;
    }
    .hamburger{
        padding: 1rem 0rem;
    }
}



/* @media screen and (min-width:769px) {
    .sm-menu{
        display: none;
    }
}

@media screen and (max-width:768px) {
    .menu{
        display: none;
    }
    .sm-menu{
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: larger;
        margin: 0rem 1rem;
    }
    .hamburger{
        display: flex;
        align-items: center;
    }
    .burgers{
        display: flex;
        flex-direction: column;
        border: 1px solid orange;
    }
    .burger{
        color: #fff;
        padding: 1.5rem;
        font-size: larger;
        margin: 1rem;
    }
} */




















/* .app__nav{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0.7rem 4rem;
    background: rgba(0,0,0,1);
}
.logo,.app__nav-toggle{
    display: none;
}
.logo-container{
    width: 2.5rem;
    height: 3rem;
}
img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.nav-link{
    margin: 0.2rem 0;
    padding: 0.8rem 3.5rem;
    font-size: 1.3rem;
    line-height: 1rem;
    color:  #fff;
}
.nav-link:hover,.nav-link:active{
    border-bottom: 1px solid orange;
    font-weight: 600;
    color: orange;
    background-color: rgba(0,0,0,0.5);
    border-radius: 0.5rem 0.5rem 0 0;
}
.app__menu-nav{
    display: none;
    background-color: #000;
    color: #fff;
}
.app__menu-nav-top{
}

@media screen and (min-width: 320px) {
    .app__nav,.app__nav-menu{
        display:none;
    }
    .app__nav-toggle,.logo{
        display:flex;
        justify-content: space-evenly;
    }
    .app__menu-nav{
        margin-bottom: 0.2rem;
        padding: 1rem 2rem;
        display: flex;
        flex-direction: column;
        
    }
    .app__menu-nav-top{
        display: flex;
        justify-content: space-between;
    }
    .logo{
        width: 1.8rem;
        height: 2rem;
    }
    .logo a img{
        height:100%;
        width:100%;
        object-fit:contain;
    }
    .app__nav-toggle{
        margin: 0;
        padding: 0;
    }
    .app__nav-menu{
        height: 80vh;
        margin-top: 0.5rem;
        margin-bottom: 4rem;
        display:flex;
        flex-direction:column;
        justify-content: center;
        align-items: center;
        background: rgba(0,0,0,0.9);
        border-radius: 0.2rem;
        z-index: 1;
    }
@media screen and (min-width: 768px) {
    .app__nav,.app__nav-menu{
        display:none;
    }
    .app__nav-toggle,.logo{
        display:flex;
        justify-content: space-between;
    }
    .app__menu-nav{
        display: flex;
        flex-direction: column;
    }
    .app__menu-nav-top{
        display: flex;
        justify-content: space-between;
    }
    .logo{
        width: 1.8rem;
        height: 2rem;
    }
    .logo a img{
        height:100%;
        width:100%;
        object-fit:contain;
    }
    .app__nav-menu{
        display:flex;
        flex-direction:column;
        justify-content: center;
        align-items: center;
        background: rgba(0,0,0,0.9);
    }
}
@media screen and (min-width: 1440px) {
    .app__menu-nav{
        display: none;
    }
    .app__nav{
        display: flex;
    }
}
@media screen and (min-width: 1024px) {
    .app__menu-nav,.app__nav-menu{
        display: none;
    }
    .app__nav{
        display: flex;
    }
}
} */
