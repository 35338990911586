.app__card{
    padding: 0.8rem;
    display: flex;
    flex-direction: column;
    color: #25D366;
    background: rgba(0, 0, 0, 1);
    border-radius: 0.2rem;
    justify-content:space-between;
}
.app__card h1{
    height: 15rem;
    display: flex;
    align-items: center;
    justify-content: center;
    /* justify-content: center;
    align-items: center; */
}
.app__card-bottom{
    margin: 0;
    padding: 0.2rem;
    background: #25D366;
    /* width: 50%; */
    align-items: center;
    justify-content: center;
    /* height: 50%; */
    /* outline: none; */
    border: none;
}

/* Mobile responsiveness */
@media screen and (max-width:425px) {
    .app__card{
        font-size:x-large;
    }
    .app__card h1{
        font-size: xx-large;
    }
}
@media screen and (max-width: 375px) {
    .app__card,.app__card h1{
        font-size:larger;
    }
}
@media screen and (min-width: 320px) {
    .app__card, .app__card h1{
        padding: 0.4rem;
        font-size:larger;
    }
}