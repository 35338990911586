.contact{
    padding: 10rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #132733;
    font-family: Montserrat,Tiny5;
}
.contact h5{
    margin: 0.5rem;
}
.contactBody{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 40%;
    height: auto;
}
.contactHead{
    padding: 1rem;
    font-size: xx-large;
    color: #25D366;
}
.getSet{
    color: #25D366;
}
.contactBody p{
    padding: 0 0 1rem;
}
.cItems{
    display: flex;
    flex-direction: column;
    padding: 1rem 0;
    list-style: none;
}
.cItems li{
    padding: 0.5rem 0;
    /* align-items: center;
    text-align: center; */
}

/* Small devices */
@media screen and (max-width:768px){
    .contact{
        width: 100%;
        padding: 10rem 0;
        font-size: medium;
    }
    .contactBody{
        width: 75%;
    }
    .contactHead{
        font-size: x-large;
    }
}
@media screen and (max-width:425px){
    .contact{
        width: 100%;
        padding: 10rem 0;
        font-size: medium;
    }
    .contactBody{
        width: 85%;
    }
    .contactHead{
        font-size: x-large;
    }
}
@media screen and (max-width:375px){
    .contact{
        width: 100%;
        padding: 10rem 0;
        font-size: small;
    }
    .contactBody{
        width: 85%;
    }
    .contactHead{
        font-size: x-large;
    }
}
@media screen and (max-width:320px){
    .contact{
        width: 100%;
        padding: 10rem 0;
        font-size: small;
    }
    .contactBody{
        width: 85%;
    }
    .contactHead{
        font-size: x-large;
    }
}















/* 
.app__contact-contain{
    display: flex;
    flex-direction: column;
    height: 100vh;
}
.contact-home{
    color: #000;
    margin-top: 5rem;
    padding-top: 5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.app__contact-details-contain{
    display: flex;
    flex-direction: column;
}
.app__contact-detail{
    display: flex;
    justify-content: center;
    align-items: center;
}
#app__contact-span{
    display: flex;
    flex-direction: column;
    background-color: #000;
    border-radius: 5rem;
}

.app__contact-detail-text p,span{
    padding: 0.8rem 1rem;
    font-weight: 500;
    color: #132733;
}
@media screen and (min-width: 320px) and (max-width:768px) {
     .app__contact{
        margin: 0;
        font-size:medium;
        justify-content: center;
        align-items: center;
    }
    .app__contact h1{
        margin-bottom: 2.5rem;
    }
    #app__contact-span{
        margin-left: 1rem;
    } 
}
@media screen and (min-width:375px) and (max-width:425px) {
    .app__contact{
        margin: 0;
        font-size:medium;
    }
    .app__contact h1{
        margin-bottom: 2.5rem;
    }
    #app__contact-span{
        margin-left: 2.8rem;
    } 
}
@media screen and (min-width: 426px) {
    .app__contact{
        margin: 0;
        font-size:medium;
    }
    .app__contact h1{
        margin-bottom: 2.5rem;
    }
    #app__contact-span{
        margin-left: 2.8rem;
    }
} */