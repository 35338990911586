.app__service-contain{
    display: flex;
    /* position: relative; */
    flex-direction: column;
    justify-content: center;
    cursor: pointer;
    background-color: rgba(0, 0, 0,0.2);
    border-radius: 0.3rem;
}

.app__service-desc{
    display: none;
    /* position: absolute; */
    height: 18rem;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 0.3rem;
    
}
.app__service-desc .app__service-desc-head{
    padding: 2rem 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #000;
    color: #25D366;
    font-weight: 500;
}
.app__service-desc .app__service-desc-text{
    margin: 0;
    padding: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    line-height: 1.5rem;
    font-weight: 200;
}
.app__service-contain:hover .app__service-card-container{
    display: none;
}
.app__service-contain:hover .app__service-desc{
    display: block;
}

/* Mobile responsive */
@media screen and (max-width:768px){
    .app__service-desc-head{
        font-size:large;
    }
    .app__service-desc-text{
        font-size: small;
    }
}
@media screen and (max-width:425px){
    .app__service-desc-head{
        font-size:medium;
    }
    .app__service-desc-text{
        font-size: small;
    }
}
@media screen and (max-width:375px){
    /* .app__service-contain{
        width: 100%;
    } */
    .app__service-contain{
        margin: 0;
        width: 100%;
    }
    .app__service-desc-head{
        font-size:medium;
    }
    .app__service-desc-text{
        font-size: smaller;
    }
}
@media screen and (min-width: 320px) {
    .app__service-card{
        top: -3.8rem;
    }
    /* .app__service-desc-head{
        font-size:medium;
    }
    .app__service-desc-text{
        font-size: smaller;
    } */
}
@media screen and (max-width:320px) {
    .app__service-contain{
        width: auto;
    }
}