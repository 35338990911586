.hero{
    /* display: flex; */
    color: #fff;
    background-color: #000;
    /* justify-content: space-around;
    align-items: center; */
    font-family: Montserrat,Tiny5;
}
.heroContent{
    display: flex;
    /* flex-direction: column; */
    /* justify-content: space-around; */
    align-items: center;
    justify-content: space-between;
    padding: 0rem 4rem;
    height: 87vh;
}
.hero-pictoral{
    background-color: transparent;
    width: 50%;
}
.txt{
    display: flex;
    flex-direction: column;
    justify-content: center;
    
    align-items: center;
}
.heroTitle{
    font-size: 10rem;
    /* color: #132733; */
}
.heroSub{
    display: flex;
    font-size: 1.5rem;
    color: #25D366;
    /* color: #132733; */
    font-family: Dancing Script;
    background-color: #132733;
    padding: 0.5rem;
    margin: 0.2rem;
    border-radius: 0.2rem;
}

/* Other devices */
@media screen and (max-width:768px){
    .heroContent{
        display: flex;
        justify-content: center;
        flex-direction: column;
        height: 100vh;
        align-items: center;
    }
    .txt{
        margin-bottom: 1rem;
    }
    .heroSub{
        padding: 0.5rem;
        font-weight: 800;   
    }
    .hero-pictoral{
        width: auto;
        margin-top: 1rem;
    }
}
@media screen and (max-width:425px) {
    .heroContent{
        padding: 0rem 3rem;
    }
    .heroTitle{
        font-size: 7.5rem;
    }
    .hero-pictoral{
        font-size:medium;
        width: 100%;
    }
}
@media screen and (min-width:321px) and (max-width:425px){
    .heroContent{
        display: flex;
        justify-content: center;
        flex-direction: column;
        height: 100vh;
        align-items: center;
    }
    .txt{
        padding-top: 3rem;
        padding-bottom: 1.5rem;
    }
    /* .heroTitle{
        font-size: 5rem;
    } */
    .heroSub{
        font-size: 1rem;
    }
    .hero-pictoral{
        /* font-size:small; */
        /* width: auto; */
    }
}
@media screen and (max-width:375px) {
    .heroTitle{
        font-size: 6.5rem;
    }
    .hero-pictoral{
        font-size: small;
    }
}
@media screen and (max-width:320px){
    /* */
    .heroContent{
        justify-content: center;
        flex-direction: column;
        height: 100vh;
    }
    .txt{
        padding-top: 5rem;
        padding-bottom: 1.5rem;
    } 
    .heroTitle{
        font-size: 6rem;
    }
    .heroSub{
        font-size: 1rem;
    }
    .hero-pictoral{
        font-size: small;
        width: auto;
    }
}






















/* .app__home-contain{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    height: 100vh;
    width: 100%;
    position: relative;
}
.app__home-contain .img{
    position: absolute;
    top: 4rem;
    height: 100%;
    width: 100%;
    object-fit: cover;
    z-index: -1;
}
.app__home{
    position: absolute;
    display: flex;
    width: 100%;
}
.home-nav{
    position: relative;
}
.app__home-hero{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.app__home-hero_right{
    display: flex;
    flex-direction: column;
    margin: 6rem 5rem;
}



.app__home-hero_right{
    display: none;
    width: 50%;
    margin-top: 0;
    margin-left: 3rem;
}
.social-link{
    margin-top: 2rem;
    margin-bottom: 2rem;
    padding: 0.5rem 1rem;
    border-radius: 1rem;
}
.app__home-footer {
    background-color: rgba(0,0,0,0.7);
    width: 100%;
    bottom: 0rem;
}
.smallbody,.home-bg{
    display: none;
}




@media screen and (min-width: 320px) and (max-width: 768px) {
    .app__home-contain{
        height: 100vh;
        width: auto;
        background: linear-gradient(orange,#000);
    }
    .app__home-contain .img{
        object-fit: fill;
        height: auto;
        z-index: 0;
    }
    .app__home{
        flex-direction: column;
        justify-content: center;
        align-items: center;
        
    }
    .app__home-hero{
        margin-top: 1rem;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        
    }
    .app__home-hero_left{
        margin: 0;
    }
    .app__home-hero_left{
        position: relative;
        display: flex;
        flex-direction: column;
        width: 85%;
        border-radius: 0.1rem;
        background: transparent;
    }
    
    .app__home-hero_left .head{
        position: absolute;
        width: 100%;
        margin: 0;
        margin-top: 1rem;
        padding: 0;
        bottom: -12.5rem;
    }
    .app__home-hero_left .body{
        bottom: -27rem;
    }
    
    .app__home-hero_left .head h1{
        font-size: 4rem;
        color: orange;
        text-shadow: 2.5px 2.5px #000;
    }
    .app__home-hero_left .head p{
        width: 30%;
        padding: 0.8rem;
        font-size: 1rem;
        color: orange;
        font-weight: 600;
        background: rgba(0,0,0,0.5); 
        border-radius: 0rem 1rem 1rem 0rem;
        bottom: 0;
    }
   
    .body{
        display: none;
    }
    .smallbody{
        position: absolute;
        display: block;
        bottom: -26rem;
        color: #fff;
    }
    .smallbody p{
        margin: 0rem 2rem;
        padding: 0.3rem 1rem;
    }
}

@media screen and (min-width: 320px){
    .app__home-hero_left .head p{
        width: 70%;
    }
}
@media screen and (min-width: 321px) and (max-width: 375px){
    .app__home-hero_left{
        margin-top: -2rem;
    }

    .app__home-hero_left .head p{
        width: 55%;
        padding: 1rem;
        font-size: 1rem;

    }
    .smallbody{
        position: absolute;
        display: block;
        bottom: -27rem;
        color: #fff;
    }
}
@media screen and (min-width: 376px) and (max-width: 425px){
    .app__home-hero_left .head{
        position: absolute;
        bottom: -15rem;
    }
    .app__home-hero_left .head p{
        width: 45%;
    }
    .body p{
        margin: 0.2rem 0;
    }
    .smallbody{
        position: absolute;
        display: block;
        bottom: -28rem;
        color: #fff;
    }
}
@media screen and (min-width: 426px) and (max-width: 768px){
    .app__home-hero_left .head{
        position: absolute;
        bottom: -10rem;
    }
    .app__home-hero_left .head p{
        width: 25%;
    }
    .body p{
        margin: 0.2rem 0;
    }
    .body{
        padding: 1rem;
        position: absolute;
        display: flex;
        flex-direction: column;
        top: 12rem;
        background: rgba(0,0,0,0.4);
        color: #fff;
        height: 10rem;
        border-radius: 1rem;
    }
    .body p{
        padding: 0.3rem 1rem;
    }
    .body:hover{
        padding: 2rem;
        background: rgba(0,0,0,1);
        border-radius: 0.5rem;
        transition: 0.5s ease-in-out;
    }
    .smallbody{
        display: none;
    }
}
@media screen and (max-width: 320px){
    .app__home-hero_left{
        margin-top: -2rem;
    }
    .app__home-hero_left .body:hover{
        padding: 1.2rem;
        margin-top: 0.8rem;
    }
    .body p{
        margin: 0.2rem 0;
    }
}
@media screen and (min-width: 769px) {
    .app__home-hero_left{
        display: flex;
        justify-content: space-between;
        color: #fff;
    }
    .app__home-hero_left .head{
        width: 100%;
        margin: 5rem 4rem;
    }
    .app__home-hero_left .head h1{
        font-size: 8rem;
        color: #fff;
    }
    .app__home-hero_left .head p{
        margin-left: 1.2rem;
        padding: 0.8rem;
        font-size: 1.4rem;
        border-radius: 0 0 0.5rem 0.5rem;
        background: rgba(0,0,0,0.4)
        
    }
 
    .app__home-hero_left .body{
        margin: 0;
        padding: 1rem;
        width: 100%;
        height: 11rem;
        background: rgba(0,0,0,0.7);
        border-radius: 0.2rem;
        border: 1px solid rgba(0,0,0,0.1);
        margin-top: 10rem;
        margin-right: 2rem;
        cursor: pointer;
    }
    .app__home-hero_left .body p{
        justify-content: center;
        align-items: center;
        line-height: 1.5rem;
        background-color: transparent;
        margin: 0.3rem;
    }
    .app__home-hero_left .body:hover{
        padding: 2rem;
        background: rgba(0,0,0,1);
        border-radius: 0.5rem;
        transition: 0.5s ease-in-out;
    }
} */