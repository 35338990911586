@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400..700&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Tiny5&display=swap');


*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

a{
  text-decoration: none;
}

::-webkit-scrollbar{
  display: none;
}

























/* @import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap');

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Open Sans', sans-serif;
}
a{
  text-decoration: none;
  color: #000;
}
button{
  cursor: pointer;
}
.twitter:hover,.twitter:active, .facebook:hover,.facebook:active, .instagram:hover,.instagram:active{
  background: rgba(0, 0, 0, 0.5);
}
.footer-links{
  font-size: 0.rem;
  padding: 0.1rem;
  color: #fff;
}
.footer-links:hover,.footer-links:active{
  border-bottom: 1px solid #fff;
}
.footer-hr{
  margin: 2rem 0;
  width: 40%;
}
::-webkit-scrollbar{
  display: none;
} */

/* 
fonts
font-family: 'Lato', sans-serif;
font-family: 'Open Sans', sans-serif;
font-family: 'Playfair Display', serif;
font-family: 'Poppins', sans-serif;

*/