.matatu{
    display: flex;
    flex-direction: column;
}
.imgs{
    display: none;
}
.matatu h1{
    color: #25D366;
    /* font-family: Tiny5; */
}
.matatu-body{
    display: flex;
    justify-content: space-evenly;
    padding: 1rem;
}
.matatu-content{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.matatu-content h2{
    /* display: flex; */
    border-bottom: 2px solid #000;
    font-family: Dancing Script;
}
.matatu-content p{
    /* display: flex; */
    padding: 0 0.2rem;
    width: 85%;
    color: #fff;
}

/* Smaller devices */
@media screen and (max-width: 320px){
    .matatu h1{
        /* color: #000;
        font-family: Tiny5; */
    }
    .matatu-body{
        display: flex;
        flex-direction: column;
        /* display: flex;
        justify-content:center;
        padding: 1rem; */
        padding: 0;
    }
    .matatu-content{
        /* display: flex; */
        flex-direction: column;
        /* justify-content: space-between; */
        font-size: smaller;
        /* margin: 0.1rem 0; */
    }
    .matatu-content p{
        padding-top: 0.8rem;
    }
}

@media screen and (max-width: 375px){
    .matatu-body{
        display: flex;
        flex-direction: column;
        padding: 0;
    }
    .matatu-content{
        /* display: flex; */
        flex-direction: column;
        /* justify-content: space-between; */
        font-size: smaller;
        margin: 0.5rem 0;
    }
    .matatu-content p{
        padding-top: 0.8rem;
    }
}
@media screen and (max-width:425px){
    .matatu-body{
        display: flex;
        flex-direction: column;
        padding: 0;
        font-size: small;
    }
    .matatu-content{
        /* display: flex; */
        flex-direction: column;
        /* justify-content: space-between; */
        /* font-size: small; */
        margin: 0.5rem 0;
    }
    .matatu-content p{
        padding-top: 0.8rem;
    }
}
@media screen and (max-width: 768px){
    .matatu-body{
        display: flex;
        flex-direction: column;
        padding: 0;
        font-size: medium;
    }
    .matatu-content{
        flex-direction: column;
        margin: 0.5rem 0;
    }
    .matatu-content p{
        padding-top: 0.8rem;
    }
}