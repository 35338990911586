.app__services_tours-page{
    position: relative;
}
.travl{
    /* position: relative; */
    width: auto;
    height: auto;
    background-color: #132733;
}
.travl img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.tour{
    /* position: relative; */
    padding-bottom: 2rem;
    background-color: #132733;
}
.swiper-container{
    width: auto;
}

@media screen and (min-width: 320px) and (max-width: 374px) {
    .swiper-container{
        width: 320px;
    }
    .tour{
        /* width: 100%; */
    }
}
@media screen and (min-width:425px){
    .travl{
        /* margin-right: 1rem; */
    }
}