.show{
    /* background-color: rgb(206, 201, 201);  */
    /* background-color: transparent; */
}
.show p{
    padding: 0rem;
    line-height: 1.5rem;
    color: #fff;
    /* color: #132733; */
    /* color: #25D366; */
}