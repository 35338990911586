.services{
    display: flex;
    flex-direction: column;
    /* padding: 5rem;
    color: #fff;*/
    background-color: #132733; 
    font-family: Montserrat,Tiny5;
}
.ours{
    padding: 4rem;
    font-size: 3.3rem;
    color: #fff;
}
.app__services-service{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.app__services-service-nav{
    margin: 1rem;
    padding: 3rem;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    border: 5px solid #000;
}
.tours,.travel,.destinations{
    display: flex;
    margin: 0.5rem 1rem;
    padding: 0rem 2rem;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: large;
    
}
.tours,.travel{
    border-radius: 1.2rem;
    outline: none;
    border: none;
    padding: 1rem 2rem;
    background: #000;
    color: #fff;
}
.tours:active,.travel:active{
    /* color: #25D366; */
    background: #25D366;
    color: #132733;
}
/* .tours{
    padding: 1.3rem;
} */
.destinations{
    padding: 0;
    /* color: #25D366; */
    color: #25D366;
    font-family: Dancing Script;
}
.destinations:active{
    color: #25D366;
}
.app__services-service-display{
    margin: 1rem 2rem;
    padding: 0rem;
    width: 83.5%;
    align-items: center;
}

/* Smaller devices */
@media screen and (max-width:1024px){
    .app__services-service-display{
        /* margin: 1rem 2rem;
        padding: 0rem; */
        width: 95%;
    }
}
@media screen and (max-width:768px){
    .destinations{
        padding-left: 0.5rem;
        padding-right: 0.5rem;
    }
    .app__services-service-display{
        /* margin: 1rem 2rem;
        padding: 0rem; */
        width: 95%;
    }
}
@media screen and (max-width: 425px){
    .ours{
        font-size:xx-large;
    }
    .app__services-service-nav{
        margin: 0;
        padding: 0;
        border: none;
    }
    .tours,.travel,.destinations{
        margin: 0.02rem 0.3rem;
        font-size:medium;
    }
    .destinations{
        padding-left: 0.6rem;
        padding-right: 0.6rem;
    }
    .app__services-service-display{
        margin: 1rem 2rem;
        padding: 0rem;
        width: 89%;
    }
}
@media screen and (max-width: 375px){
    /* .services{
        font-size: small;
    } */
    .ours{
        padding: 2.2rem;
        font-size:x-large;
    }
    .app__services-service-nav{
        margin: 0;
        padding: 0;
        border: none;
    }
    .tours,.travel,.destinations{
        /* margin: 0.025rem 0.5rem;
        padding: 0rem 0.1rem; */
        margin: 0.02rem 0.3rem;
        font-size: small;
    }
    .app__services-service-display{
        /* margin: 1rem 2rem;
        padding: 0rem; */
        width: 80%;
    }
    .destinations{
        padding-left: 0.6rem;
        padding-right: 0.6rem;
    }
}
@media screen and (max-width:320px){
    .services{
        
    }
    .ours{
        padding: 2rem;
        /* font-size: 1.5rem; */
    }
    .app__services-service-nav{
        padding: 1rem;
        /* margin: 1rem; */
        /* padding: 1rem 0; */
        border: none;
    }
    .tours,.travel,.destinations{
        /* margin: 0.025rem 0.5rem;
        padding: 0rem 0.1rem; */
        font-size: smaller;
    }
    .tours,.travel{
        /* border-radius: 1rem;
        outline: none;
        border: none; */
        padding: 1rem 1.2rem;
        /* background: #000;
        color: #fff; */
    }
    .destinations{
        padding: 0;
        /* padding-left: 0.4rem;
        padding-right: 0.4rem; */
    }
    .app__services-service-display{
        margin: 1rem 1rem;
        padding: 0rem;
        width: 95%;
    }
}


























/* .app__services-service{
    justify-content: center;
    align-items: center;
    
}
.ours{
    padding: 4rem;
    font-size: 3rem;
}
.app__services-service-nav{
    margin: 2.5rem 5rem;
    padding: 3rem;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    border: 5px solid #000;
    background-color: transparent;
}
.app__services-service-nav button{
    font-size: 1.2rem;
    outline: none;
    transition: 0.2s all;
}
.app__services-service-display{
    margin: 0rem 5rem;
    justify-content: center;
}
.tours{
    padding: 1rem 8rem;
    margin: 0.2rem 1rem;
    border-radius: 1rem;
    outline: none;
    border: none;
    color: #000;
}
.travel{
    padding: 1rem 6.7rem;
    border-radius: 1rem;
    display: flex;
    color: #000;
    border: none;
}
.travel-icon{
    padding-left: 1rem;
}
.tours:hover,.travel:hover{
}
.tours:focus,.travel:focus{
    background-color: #000;
    color: #fff;
}
.destinations{
    outline: none;
    background: transparent;
    font-family: Dancing Script;
    font-size: x-large;
} */

/* @media screen and (min-width: 320px) and (max-width: 375px) {
    .app__services-service-nav{
        margin: 0;
        padding: 1rem 2rem;
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-between;
        align-items: center;
    }
    .app__services-service-nav button{
        padding: 0.5rem 2rem;
        margin: 0 0.5rem;
        width: 10rem;
    }
    .travel-icon{
        display: none;
    }
    .app__services-service-display{
        width: 90%;
        margin: 0 1rem;
    }
    .tours{
        padding: 0.6rem 6rem;
    }
    .travel{
        padding: 0.5rem 5rem;
    }
    .travel-icon{
        padding-left: 0.5rem;
    }
}
@media screen and (min-width: 376px) and (max-width:425px){
    .app__services-service-nav{
        margin: 0;
        padding: 1rem 2rem;
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-between;
        align-items: center;
    }
    .app__services-service-nav button{
        padding: 0.5rem 2rem;
        margin: 0 0.5rem;
        width: 10rem;
    }
    .travel-icon{
        display: none;
    }
}
@media screen and (min-width:426px) and (max-width:1024px){
    .app__services-service-nav{
        margin: 0;
        padding: 1rem 12rem;
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-between;
        align-items: center;
    }
    .app__services-service-nav button{
        padding: 0.5rem 2rem;
        margin: 0 0.5rem;
        width: 10rem;
    }
    .travel-icon{
        display: none;
    }
}
@media screen and (min-width: 425px) and (max-width: 768px) {
    .app__services-service-display{
        width: 95%;
        margin: 0 1.8rem;
    }
}

@media screen and (min-width: 376px) and (max-width: 425px){
    .app__services-service-display{
        width: auto;
        margin: 0 1.5rem;
    }
}
@media screen and (min-width: 426px) and (max-width: 1024px){
    .app__services-service-display{
        width: auto;
        margin: 0 1.8rem;
    }
}  */